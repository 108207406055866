<template>
    <BaseContainer>
        <template slot="menu">
            <v-app-bar app dark :class="classMenu" :style="cssVars">
                <v-btn icon :color="$vuetify.theme.contrast.color" @click="onClickGoBack()">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer />
                <span :style="`text-transform: uppercase; color: ${$vuetify.theme.contrast.color};`">
                    {{ comboTitle }}<b>{{ productTitle }}</b>
                </span>
                <v-spacer />
            </v-app-bar>
        </template>
        <template slot="principal">
            <v-main>
                <v-progress-linear v-model="progress" height="20" color="primary" fixed>
                    <strong>{{ getProgress() }}</strong>
                </v-progress-linear>

                <v-card elevation="0" class="mt-4">
                    <div class="text-center" style="font-size: 28px; font-weight: bold;">
                        {{ getTitle() }}
                    </div>
                    <div style="display: flex; justify-content: center; text-align: center;">
                        <div class="mr-2">Minimo: {{ getStepQtd('min') }}</div>
                        <div class="ml-2">Máximo: {{ getStepQtd('max') }}</div>
                    </div>
                    <div class="text-center">
                        Valor Adicional: R$ {{ getValorTotalComplementos().toFixed(2) }}
                    </div>
                    <div v-if="categorias().length > 1" style="display: flex; align-items: center;">
                        <v-btn icon class="mt-1">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <div style="overflow-x: auto; white-space: nowrap; width: 100%">
                            <v-btn
                                v-for="categoria in categorias()" :key="categoria"
                                color="primary"
                                class="mr-1"
                                min-width="100px"
                                @click="categoriaFilter = categoria"
                                depressed
                                tile
                                small
                            >
                                {{ categoria }}
                            </v-btn>
                        </div>
                        <v-btn icon class="mt-1">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </div>
                    <div v-if="showPesquisa" style="margin: 0 10px 0 10px;">
                        <v-text-field
                            v-model="search"
                            dense
                            outlined
                            clearable
                            hide-details
                            label="Digite para pesquisar..."
                            prepend-inner-icon="mdi-magnify"
                        />
                    </div>
                </v-card>

                <div ref="complementsContainer" :style="`height: 70vh; overflow-x: auto; padding: 0 10px 50px 10px;`">
                    <v-card
                        class="mx-auto my-2"
                        width="600"
                        elevation="8"
                        outlined
                        tile
                        v-for="complement in complementsFiltered()"
                        :key="complement.index"
                        :disabled="isIndisponivel(complement)"
                        :style="
                            complement.quantidade > 0
                                ? `border-radius: 5px; border: solid 3px ${isDark ? '#fff' : '#666'}`
                                : ''
                        "
                        @click="maxLimit(complement.index) && onClickAddComplement(complement.index)"
                    >
                        <v-card-text style="display: flex; justify-content: space-between; padding: 20px;">
                            <div>
                                <div v-if="complement.controle_estoque" class="stock-chip">
                                    <span class="caption">{{ getEstoqueText(complement) }}</span>
                                </div>
                                <div class="product-title" :style="`color: ${isDark ? '#fff' : '#111'}`">
                                    <v-img
                                        v-if="getUrl(complement.imagens)"
                                        :src="getUrl(complement.imagens)"
                                        max-width="50"
                                        height="50"
                                        style="border-radius: 5px;"
                                        class="mr-2"
                                    />
                                    <span>
                                        {{ complement.descricao }}
                                    </span>
                                </div>
                                <div class="product-details" :style="`color: ${isDark ? '#d2d2d2' : '#666'}`">
                                    <span>
                                        {{ complement.detalhes }}
                                    </span>
                                </div>
                                <v-chip
                                    v-if="+complement.valor"
                                    small
                                    outlined
                                    color="#999"
                                    :text-color="isDark ? '#fff' : '#444'"
                                >+ R$ {{ formatPrice(+complement.valor) }}</v-chip>

                                <v-chip
                                    v-if="isIndisponivel(complement)"
                                    small
                                    color="primary"
                                    text-color="white"
                                    class="ml-2"
                                >Indisponível</v-chip>
                            </div>
                            <div style="min-width: 105px;">
                                <v-btn
                                    icon
                                    :disabled="(complement.quantidade || 0) <= 0"
                                    @click.stop="onClickDelComplement(complement.index)"
                                >
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <!-- <input
                                    @click.stop
                                    @change="onChangeQtd(complement.index)"
                                    v-model="complement.quantidade"
                                    type="tel"
                                    style="max-width: 30px; text-align: center;"
                                    v-mask="['###']"
                                > -->
                                <b>{{ complement.quantidade || 0 }}</b>
                                <v-btn
                                    icon
                                    :disabled="!maxLimit(complement.index)"
                                    @click.stop="onClickAddComplement(complement.index)"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>

                <v-footer fixed class="pa-4">
                    <v-btn
                        color="primary"
                        x-large
                        block
                        @click="onClickNext"
                    >
                        Continuar
                    </v-btn>
                </v-footer>

                <v-dialog v-model="dialog.show" persistent max-width="300">
                    <v-card>
                        <v-card-title class="headline justify-center" style="word-break: break-word;">
                            {{ dialog.text }}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" style="color: white;" @click="dialogOk()" block>Ok</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-main>
        </template>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import { valorTotalComplementos } from '@/utils/calc';
import { clone } from '@/utils/utils';
import { mapMutations } from 'vuex';

export default {
    name: 'Steps',

    components: { BaseContainer },

    data: () => ({
        search: '',
        cart: null,
        storedProduct: null,
        currentProductIndex: null,
        currentStepIndex: 0,
        progress: 0,
        steps: [],
        categoriaFilter: '',
        dialog: {
            text: '',
            show: false,
        },
    }),

    computed: {
        isDark() {
            return this.$vuetify.theme.isDark;
        },

        classMenu() {
            return this.isDark ? 'app-bar-dark' : 'app-bar-light';
        },

        cssVars() {
            return {
                '--bg-color': this.$vuetify.theme.custom.appBarColor,
                'padding': '0 0 10px 0',
            }
        },

        currentProduct() {
            let currentProduct = this.storedProduct;

            if (currentProduct?.produtos?.length) {
                return currentProduct.produtos[this.currentProductIndex];
            }
            return currentProduct;
        },

        currentStep() {
            return this.currentProduct?.passos[this.currentStepIndex];
        },

        productTitle() {
            return this.currentProduct?.descricao || '';
        },

        comboTitle() {
            return this.storedProduct?.produtos?.length ? (this.storedProduct.descricao + ' / ') : '';
        },

        combo() {
            return this.storedProduct?.produtos?.length ? this.storedProduct.produtos : null;
        },

        showPesquisa() {
            const complementos = this.complements();
            return complementos.length > 10;
        },

        getListHeight() {
            const height = this.showPesquisa ? 0 : 75;
            return this.$vuetify.breakpoint.height + height - 310;
        },
    },

    watch: {
        currentProduct: function () {
            if (!this.combo) {
                return;
            }

            this.dialog = {
                text: 'Escolha as opções para ' + this.productTitle,
                show: true
            }
        },
        deep: true,
    },

    mounted() {
        this.initialize();
    },

    methods: {
        ...mapMutations({
            setCart: 'setCart',
        }),

        initialize() {
            this.cart = this.$store.getters.getCart;
            const product = this.$store.getters.getCurrentProduct;

            this.storedProduct = product;

            this.storedProduct?.produtos?.length &&
                (this.currentProductIndex = this.storedProduct.produtos.findIndex(c => c.passos?.length));

            this.fillSteps();

            this.steps = this.currentProduct?.passos;
        },

        fillSteps() {
            this.currentProduct.passos.forEach(s => {
                s.complementos.forEach(a => {
                    a.quantidade = 0;
                });
            });

            this.$forceUpdate();
        },

        getValorTotalComplementos() {
            return valorTotalComplementos(this.currentStep);
        },

        complements() {
            return this.currentProduct?.passos[this.currentStepIndex]?.complementos || [];
        },

        complementsFiltered() {
            const complements = clone(this.complements());
            complements.forEach((c, index) => (c.index = index));

            if (this.search) {
                return complements.filter(c =>
                    c.descricao.trim().toLowerCase().includes(this.search.trim().toLowerCase())
                );
            }

            return !this.categoriaFilter
                ? complements
                : complements.filter(c => c.categoria.descricao == this.categoriaFilter);
        },

        categorias() {
            const complementos = this.complements(),
                uniques = [];

            complementos.forEach(c => !uniques.includes(c.categoria.descricao) && uniques.push(c.categoria.descricao));

            return uniques;
        },

        maxLimit(index) {
            const qtdMaxGeral = this.getStepQtd('max');
            const qtdTotalGeral = this.currentStep.complementos.reduce((a, b) => a + (+b.quantidade || 0), 0);

            const complemento = this.currentStep.complementos[index];
            const qtdAtual = complemento.quantidade || 0;
            const qtdMax = complemento.max || qtdMaxGeral;
            const limiteEstoque = complemento.estoque || 0;

            if (complemento.controle_estoque && qtdAtual >= limiteEstoque) {
                return false;
            }

            if (qtdAtual >= qtdMax) {
                // this.notify('Quantidade maxima permitda ' + qtdMax, 'warning');
                return false;
            }

            if (qtdTotalGeral >= qtdMaxGeral) {
                // this.notify('Quantidade maxima permitda ' + qtdMaxGeral, 'warning');
                return false;
            }

            return true;
        },

        onClickAddComplement(index) {
            const comp = this.currentStep.complementos[index];
            !comp.quantidade && (comp.quantidade = 0);

            comp.quantidade++;
            this.$forceUpdate();
        },

        onClickDelComplement(index) {
            const qtdAtual = this.currentStep.complementos[index].quantidade;
            // const qtdMin = this.currentStep.complementos[index].min;

            // if (qtdAtual <= qtdMin) {
            //     this.notify('Quantidade minima permitda ' + qtdMin, 'warning');
            //     return;
            // }

            if (qtdAtual <= 0) {
                return;
            }

            this.currentStep.complementos[index].quantidade--;
            this.$forceUpdate();
        },

        // onChangeQtd(index) {
        //     const comp = this.currentStep.complementos[index];

        //     const qtdMaxGeral = this.getStepQtd('max');
        //     const qtdTotalGeral = this.currentStep.complementos.reduce((a, b) => a + (+b.quantidade || 0), 0);

        //     if (qtdTotalGeral > qtdMaxGeral) {
        //         comp.qtd = qtdTotalGeral - qtdMaxGeral;
        //     }
        // },

        scrollTop() {
            this.$refs.complementsContainer.scrollTop = 0;
        },

        onClickNext() {
            const stepsLength = (this.currentProduct?.passos || []).length - 1;
            const qtdComplementos = this.currentStep.complementos.reduce((a, b) => a + (+b.quantidade || 0), 0);
            const qtdMinima = +this.currentStep.min;
            const complementoAbaixoMin = this.currentStep.complementos
                .find(c => +c.quantidade !== 0 && +c.quantidade < +c.min);

            if (qtdMinima && qtdComplementos < qtdMinima) {
                this.notify(`Escolha no mínimo ${qtdMinima}`, 'warning');
                return;
            }

            if (complementoAbaixoMin) {
                this.notify(
                    `Quantidade minima ${complementoAbaixoMin.min} para ${complementoAbaixoMin.descricao}`,
                    'warning'
                );
                return;
            }

            this.search = '';
            this.categoriaFilter = '';

            if (this.currentStepIndex < stepsLength) {
                this.currentStepIndex++;
                this.scrollTop();
                return;
            }

            // Remove passos e complementos zerados
            this.currentProduct.passos.forEach((s, index) => {
                let empty = !s.complementos.reduce((a, b) => a + +(b.quantidade || 0), 0);
                if (empty) {
                    delete this.currentProduct.passos[index];
                }

                if (this.currentProduct.passos[index]) {
                    s.complementos = s.complementos.filter(a => +(a.quantidade || 0) > 0);
                }
            });

            this.currentProduct.passos = this.currentProduct.passos.filter(n => n)

            const combo = this.combo;
            if (combo) {
                this.storedProduct.produtos[this.currentProductIndex] = clone(this.currentProduct);

                // Proximo produto do combo que contem passos
                const index = combo.findIndex((c, index) =>
                    c.passos?.length && index > this.currentProductIndex) || -1;

                if (index !== -1) {
                    this.currentProductIndex = index;
                    this.currentStepIndex = 0;
                    this.fillSteps();
                    return;
                }
            }

            this.cart.push(this.combo ? this.storedProduct : this.currentProduct);
            this.setCart(this.cart);
            this.$router.replace(`/${this.$route.params.emp}/resumo`);
        },

        onClickGoBack() {
            if (this.currentStepIndex === 0) {
                this.$router.push(`/${this.$route.params.emp}`);
                return;
            }

            this.search = '';
            this.categoriaFilter = '';
            this.currentStepIndex--;
        },

        dialogOk() {
            this.dialog = {
                text: '',
                show: false,
            };
        },

        getStepQtd(param) {
            if (param === 'min') {
                return this.currentStep?.min || 0;
            }

            return this.currentStep?.max || 20;
        },

        getProgress() {
            const stepsLength = (this.currentProduct?.passos || []).length;
            const progress = this.currentStepIndex + 1;
            this.progress = 100 / stepsLength * progress;

            return `${progress}/${stepsLength}`;
        },

        getTitle() {
            return this.currentStep?.titulo || '';
        },

        getEstoqueText(item) {
            if (item.estoque <= 0) {
                return '';
            }

            return item.estoque > 1
                ? `Restam ${item.estoque} unidades`
                : 'Resta apenas 1 unidade';
        },

        isIndisponivel(complement) {
            const outOfStock = complement.controle_estoque && complement.estoque <= 0;
            return (complement.status == 'inativo') || outOfStock;
        },

        getUrl(images) {
            if (!images?.length) {
                return false;
            }

            const img = images[0];

            if (!img?.url) {
                return false;
            }

            const url = img.tmp
                ? `${this.$urlAPI}/files/tmp/${img.url}`
                : `${this.$urlAPI}/storage/${img.url}`;

            return url;
        },
    },
}
</script>

<style scoped>
.app-bar-dark {
    background-color: var(--bg-color) !important;
}

.app-bar-light {
    background-color: var(--bg-color) !important;
}

.product-title {
    display: flex;
    text-transform: uppercase;
    font-size: 16px;
}

.product-details {
    font-size: 14px;
    line-height: 20px;
    padding: 2px 0 2px 0;
}

.stock-chip {
    margin: -20px 0 10px -20px;
    background: #8009e5;
    color: white;
    border-radius: 2px 5px 5px 5px;
    padding: 0 5px;
    width: 150px;
}
</style>
